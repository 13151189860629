import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  const url = "https://yucolab-hongkong.slack.com/archives/C01F7MMDNMC/p1606215627000200";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
